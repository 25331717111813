import { Injectable, inject } from '@angular/core';
import { BackendService } from './backend.service';
import { UserService } from './user.service';
import { catchError, map, of, switchMap, tap, throwError } from 'rxjs';
import { User } from '../types/types';
import { PortfolioListService } from './portfolio-list.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { debug } from '../utility/utility';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private backendService = inject(BackendService);
    private userService = inject(UserService);
    private portfolioList = inject(PortfolioListService);
    private router = inject(Router);

    public loginUser(email: string, password: string) {
        this.userService.cleanUserData();
        return this.backendService.post$<{ accessToken: string }>('/login', { email, password }).pipe(
            tap((response) => {
                this.userService.saveAccessToken(response.body!.accessToken);
            }),
            switchMap(() => this.backendService.get$<User>('/get-user/' + email)),
            tap((response) => {
                this.userService.storeUser(response.body!);
            }),
        );
    }
    public registerUser(email: string, password: string, first_name: string, last_name: string) {
        return this.backendService.post$('/register', { email, password, first_name, last_name });
    }
    public saveUser(newUser: User) {
        return this.backendService.post$<User>('/save-user', { user: newUser }).pipe(
            tap((response) => {
                this.userService.storeUser(response.body!);
            }),
        );
    }
    public activateUser(code: string) {
        return this.backendService.get$<{ message: string }>(`/activate?code=${code}`).pipe(
            map((response) => response.body!.message),
            catchError((error: HttpErrorResponse) => {
                return of(error.error.message as string);
            }),
        );
    }
    public resetPassword(uri: string) {
        return this.backendService.post$<{ message: string }>('/reset-password', { email: uri });
    }
    public updatePassword(uri: string, password: string, code: string) {
        return this.backendService.post$<{ message: string }>('/update-password', { uri, password, code });
    }
    public removeUser(uri: string) {
        return this.backendService.post$('/remove-user', { uri }).pipe(
            tap(() => {
                this.userService.cleanUserData();
            }),
        );
    }
    public logout() {
        this.userService.cleanUserData();
        this.portfolioList.reset();
        this.router.navigate(['/login']);
    }
}
